import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './App.css'; // Ensure your custom styles are correctly applied

function PrivacyPolicy() {
  return (
    <>
      {/* Hero Section */}
      <Container fluid className="hero-section bg-primary text-white text-center py-5">
        <Row className="justify-content-center align-items-center min-vh-100">
          <Col md={8} lg={6}>
            <h1 className="display-3 mb-3">Privacy Policy</h1>
            <p className="lead mb-4">Your privacy is important to us. Here's how we handle your data.</p>
          </Col>
        </Row>
      </Container>

      {/* Privacy Policy Content Section */}
      <Container className="py-5">
        <Row>
          <Col md={12}>
            <h2 className="text-center mb-4">Introduction</h2>
            <p>Welcome to our Privacy Policy. We value your privacy and are committed to protecting your personal data. This policy outlines how we collect, use, and safeguard your information.</p>

            <h3 className="mt-4">1. Information We Collect</h3>
            <p>We collect various types of information, including personal details, browsing data, and usage statistics.</p>

            <h3 className="mt-4">2. How We Use Your Data</h3>
            <p>Your data is used to improve our services, communicate with you, and provide a better user experience.</p>

            <h3 className="mt-4">3. Data Protection</h3>
            <p>We employ security measures to protect your data from unauthorized access and misuse.</p>

            <h3 className="mt-4">4. Your Rights</h3>
            <p>You have rights regarding your personal data, including access, correction, and deletion requests.</p>

            <h3 className="mt-4">5. Contact Us</h3>
            <p>If you have any questions about our privacy practices, please contact us:</p>
            <p><strong>Phone:</strong> <a href="tel:+212694146079">+212 694 146 079</a></p>
            <p><strong>Email:</strong> <a href="mailto:contact@reversecube.net">contact@reversecube.net</a></p>
          </Col>
        </Row>
      </Container>

      {/* Footer */}
      <footer className="bg-dark text-white text-center py-3">
        <Container>
          <p className="mb-0">&copy; {new Date().getFullYear()} Reversecube. All rights reserved.</p>
        </Container>
      </footer>
    </>
  );
}

export default PrivacyPolicy;
