import {
  Banner,
  Button,
  Card,
  Layout,
  Page,
  TextField,
} from '@shopify/polaris';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './GetProducts.css';

function GetProducts({ shop }) {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');


  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('/fetch-products');
        setProducts(response.data);
        setFilteredProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
        setShowError(true);
        setErrorMessage(
          'There was an error fetching the products. Please try again.'
        );
      }
    };

    fetchProducts();
  }, []);

  const handleSearchChange = (value) => {
    setSearchQuery(value);
    if (value.trim() === '') {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter((product) =>
        product.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  };

  const handleAddProduct = async (productId) => {
      // const response = await axios.post(`/add-product/${productId}`);
      const url = `/add-product/${productId}`;
      const headers = {
          'Shop': shop, // Use the shop in the headers
      };
    try {
      const response = await axios.post(url, null, { headers });

      setShowSuccess(true);
      setSuccessMessage('Product added successfully!');
      setTimeout(() => setShowSuccess(false), 3000); // Auto-close success alert after 5 seconds
    } catch (error) {
      setShowError(true);
      setErrorMessage('There was an error adding the product. Please try again.');
      setTimeout(() => setShowError(false), 3000); // Auto-close error alert after 5 seconds
    }
  };


  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          <CSSTransition
              in={showError}
              timeout={300}
              classNames="alert"
              unmountOnExit
            >
              <Banner
                title="Error"
                status="critical"
                onDismiss={() => setShowError(false)}
              >
                {errorMessage}
              </Banner>
            </CSSTransition>

            <CSSTransition
              in={showSuccess}
              timeout={300}
              classNames="alert"
              unmountOnExit
            >
              <Banner
                title="Success"
                status="success"
                onDismiss={() => setShowSuccess(false)}
              >
                {successMessage}
              </Banner>
            </CSSTransition>
          <div className="search-container">
            <TextField
              className="searchInput"
              label=""
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search by product name"
              autoComplete="off"
            />
          </div>

          <TransitionGroup className="product-grid">
            {filteredProducts.map((product) => (
              <CSSTransition
                key={product.id}
                timeout={500}
                classNames="product"
              >
                <Card sectioned>
                  <div className="product-container">
                    <img
                      src={product.image}
                      alt={product.name}
                      className="product-image"
                    />
                    <div className="product-details">
                      <h2 className="product-name">{product.name}</h2>
                      <p className="product-price">
                        ${product.price.toFixed(2)}
                      </p>
                      <Button
                        primary
                        className="custom-add-button"
                        onClick={() => handleAddProduct(product.id)}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                </Card>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default GetProducts;
