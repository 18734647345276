import createApp from '@shopify/app-bridge';
import { Redirect } from '@shopify/app-bridge/actions';
import { AppProvider as PolarisProvider } from '@shopify/polaris';
import '@shopify/polaris/build/esm/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import GetProducts from './GetProducts';
import Home from './Home';
import Landing from './Landing';
import Privacypolicy from './Privacy-policy';

function App() {
  const params = new URLSearchParams(window.location.search);
  const host = params.get('host') || 'https://palace.reversecube.net'; // Default host
  const shop = params.get('shop'); // Extract the shop from the URL


  // Only use Shopify App Bridge on /app and /app/get-products routes
  if (window.location.pathname.startsWith('/app')) {
    if (!host) {
      return <div>Error: Host parameter is missing.</div>;
    }
    
    const appBridgeConfig = {
      apiKey: '4afa6f6db20014f577b891d104f011f2',
      host: host,
      forceRedirect: true,
    };

    const app = createApp(appBridgeConfig);

    return (
      <PolarisProvider i18n={{}}>
        <MyRouter app={app} shop={shop}/>
      </PolarisProvider>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        {/* <Route path="/set-products" element={<SetProducts />} /> */}
      </Routes>
    </Router>
  );
}

function MyRouter({ app, shop }) {
  const redirect = Redirect.create(app);

  const params = new URLSearchParams(window.location.search);
  const host = params.get('host') || 'https://palace.reversecube.net'; // Default host

  useEffect(() => {
    if (!host) {
      redirect.dispatch(Redirect.Action.APP, '/app/auth');
    }
  }, [host, redirect]);

  return (
    <Router>
      <Routes>
        <Route path="/app" element={<Home />} />
        <Route path="/app/get-products" element={<GetProducts shop={shop} />} />
      </Routes>
    </Router>
  );
}

export default App;
