import { Button, Card, Layout, Page } from '@shopify/polaris';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css'; // Import the CSS file

function Home() {
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    navigate('/app/get-products');
  };

  return (
    <Page title="Welcome to Palace App">
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <div className="centered-content">
              <h1 className="heading">Welcome to Palace App for Shopify</h1>
              <p className="subdued-text">Adding products to your Shopify store made easy by Reversecube</p>
              <Button primary onClick={handleGetStartedClick}>
                Get Started
              </Button>
            </div>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default Home;
