import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // Ensure your custom styles are correctly applied

function Landing() {
  return (
    <>
      {/* Hero Section */}
      <Container fluid className="hero-section bg-primary text-white text-center py-5">
        <Row className="justify-content-center align-items-center min-vh-100">
          <Col md={8} lg={6}>
            <h1 className="display-3 mb-3 animate__animated animate__fadeIn">Welcome to Palace App for Shopify</h1>
            <p className="lead mb-4 animate__animated animate__fadeIn animate__delay-1s">Simplify your Shopify store management with ease using Reversecube.</p>
            <Button variant="light" size="lg" className="btn-get-started animate__animated animate__fadeIn animate__delay-2s">Get Started</Button>
          </Col>
        </Row>
      </Container>

      {/* Features Section */}
      <Container id="features" className="py-5">
        <Row>
          <Col>
            <h2 className="text-center mb-4 animate__animated animate__fadeIn">Features</h2>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Card className="mb-4 shadow-sm border-light animate__animated animate__fadeIn animate__delay-1s">
              <Card.Body>
                <Card.Title className="mb-3">Easy Product Management</Card.Title>
                <Card.Text>Manage your products effortlessly with our intuitive and streamlined interface.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4 shadow-sm border-light animate__animated animate__fadeIn animate__delay-2s">
              <Card.Body>
                <Card.Title className="mb-3">Seamless Shopify Integration</Card.Title>
                <Card.Text>Integrate seamlessly with your Shopify store to boost productivity and efficiency.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4 shadow-sm border-light animate__animated animate__fadeIn animate__delay-3s">
              <Card.Body>
                <Card.Title className="mb-3">User-Friendly Interface</Card.Title>
                <Card.Text>Experience a user-friendly interface designed to ensure a smooth and enjoyable user experience.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Contact Section */}
      <Container id="contact" className="py-5 bg-light">
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            <h2 className="text-center mb-4 animate__animated animate__fadeIn animate__delay-1s">Contact Us</h2>
            <div className="contact-info animate__animated animate__fadeIn animate__delay-2s">
              <Row className="mb-3">
                <Col xs={12} md={4} className="text-center">
                  <div className="contact-icon">
                    <i className="fas fa-phone fa-2x"></i>
                  </div>
                  <p className="mt-2">Phone: <a href="tel:+212694146079">+212 694 146 079</a></p>
                </Col>
                <Col xs={12} md={4} className="text-center">
                  <div className="contact-icon">
                    <i className="fas fa-envelope fa-2x"></i>
                  </div>
                  <p className="mt-2">Email: <a href="mailto:contact@reversecube.net">contact@reversecube.net</a></p>
                </Col>
                <Col xs={12} md={4} className="text-center">
                  <div className="contact-icon">
                    <i className="fas fa-globe fa-2x"></i>
                  </div>
                  <p className="mt-2">Website: <a href="https://reversecube.net" target="_blank" rel="noopener noreferrer">reversecube.net</a></p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Social Media Section */}
      <Container id="social-media" className="py-5">
        <Row>
          <Col>
            <h2 className="text-center mb-4 animate__animated animate__fadeIn">Follow Us</h2>
            <div className="d-flex justify-content-center">
              <a href="https://facebook.com/reversecube" target="_blank" rel="noopener noreferrer" className="mx-3 animate__animated animate__fadeIn animate__delay-1s">
                <i className="fab fa-facebook fa-3x"></i>
              </a>
              <a href="https://twitter.com/reversecubenet" target="_blank" rel="noopener noreferrer" className="mx-3 animate__animated animate__fadeIn animate__delay-2s">
                <i className="fab fa-twitter fa-3x"></i>
              </a>
              <a href="https://linkedin.com/company/reversecube" target="_blank" rel="noopener noreferrer" className="mx-3 animate__animated animate__fadeIn animate__delay-3s">
                <i className="fab fa-linkedin fa-3x"></i>
              </a>
              <a href="https://instagram.com/reversecube" target="_blank" rel="noopener noreferrer" className="mx-3 animate__animated animate__fadeIn animate__delay-4s">
                <i className="fab fa-instagram fa-3x"></i>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Footer */}
      <footer className="bg-dark text-white text-center py-3">
        <Container>
          <p className="mb-0">&copy; {new Date().getFullYear()} Reversecube. All rights reserved.</p>
        </Container>
      </footer>
    </>
  );
}

export default Landing;
